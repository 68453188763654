@import '/styles/base';

.container {
  display: block;
}

.container_identificator {
  display: flex;
  margin: 40px 10% 0px 30px;
  height: 120px;
  background-color: var(--ui-background-booking);
  border-radius: 12px;
  align-items: center;

  &_avatar {
    margin: 0px 30px 0px 40px;
  }

  &_name {
    font-size: var(--h3-font-size);
    font-weight: bold;
    display: flex;
  }

  &_lastName {
    margin-left: 5px;
  }
}

.orders_section {
  display: flex;
  margin: 30px 10% 0px 30px;

  &_title {
    font-size: var(--h3-font-size);
    font-weight: bold;
  }
}

.tabs_wrapper {
  display: flex;
  height: 42px;
  margin: 13px 10% 0px 30px;
  align-items: center;
}

.tab {
  cursor: pointer;
  font-size: var(--h4-font-size);

  &_second {
    margin-left: 30px;
  }
}

.tab_selected {
  font-weight: bold;
}

.tab:after {
  content: '';
  height: 4px;
  background: transparent;
  display: block;
  margin-top: 14px;
}

.tab_selected:after {
  content: '';
  height: 4px;
  background: var(--brand-color-primary);
  display: block;
  margin-top: 14px;
}

.selected_section {
  display: none;
}

.separator {
  height: 1px;
  background-color: var(--ui-background-secondary);
  opacity: 0.4;
  margin: 0px 10% 0px 30px;
}

.table_wrapper {
  margin: 20px 10% 0px 30px;
}

@include forPhone {
  .container {
    display: block;
  }

  .container_identificator {
    display: none;
  }

  .orders_section {
    display: none;
  }

  .tabs_wrapper {
    display: flex;
    height: 42px;
    margin: 0px;
    align-items: center;
    justify-content: space-evenly;
  }

  .tab {
    font-size: var(--p-font-size);

    &_second {
      margin-left: 0px;
    }
  }

  .tab_selected {
    font-weight: bold;
  }

  .selected_section {
    display: block;
    height: 4px;
    margin: 0;
    background-color: var(--brand-color-primary);
    width: 50%;
    border: none;

    &_past {
      margin-left: 50%;
    }
  }

  .tab:after {
    display: none;
  }

  .tab_selected:after {
    display: none;
  }

  .separator {
    height: 1px;
    background-color: var(--ui-background-secondary);
    opacity: 0.4;
    margin: 0;
  }

  .table_wrapper {
    margin: 10px 10px 0px 10px;
  }
}

$heightCardDesk: 55px;
$heightCardMobile: 40px;
$blockWidth: calc(50% - 28px);
$borderRadius: 12px;

.tableContainer {
  min-height: 625px;
  margin: 10px 10% 0 30px;
}

.events {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
}

.th {
  color: var(--font-color-muted);
  text-align: start;
  text-transform: uppercase;
}

.th,
.td {
  padding: 11px 0;
}

.td:first-child,
.th:first-child {
  padding-left: 25px;
}

.td:first-child {
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.td:last-child {
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.tr:nth-child(2n) .td {
  background-color: var(--ui-background-booking);
}

.title {
  font-size: var(--subtitle-font-size);
  font-weight: 600;
}

.subtitle {
  font-weight: 500;
}

.time {
  text-transform: lowercase;
}

.tdMobile {
  display: none;
}

.commandName {
  font-size: var(--subtitle-font-size);
  font-weight: 600;
  color: var(--font-color-blue);
}

.at {
  font-weight: 500;
  margin: 0 10px;
}

.build {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

// events

// leagueList
.leagueList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.block {
  width: $blockWidth;
  margin-bottom: 40px;
}

.heading {
  font-weight: bold;
  line-height: 31px;
  text-transform: uppercase;
  position: relative;
  margin-top: 0;
}

.heading::after {
  @include line;
  bottom: 0;
  left: 0;
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  height: $heightCardDesk;
  margin-right: 28px;
  padding: 0 20px 0 25px;
  border-radius: 12px;
}

.card:nth-child(2n) {
  background-color: var(--ui-background-booking);
}

.name {
  font-size: var(--subtitle-font-size);
  font-weight: 600;
  margin-right: auto;
}

.BTNFollowing {
  font-weight: 600;
  color: var(--brand-color-primary);

  &.BTNFollowingBigView {
    font-size: var(--h3-font-size);
    font-weight: 700;
    color: var(--brand-color-primary);
    padding: 1px 50px;
    border-color: var(--brand-color-primary);
  }
}

.BTNFollowingDisable {
  color: var(--font-color-muted);
  cursor: initial;
  padding-right: 10px;
}

.arrow {
  margin-left: 8px;
}

.BTNDetailsMobile {
  display: none !important;
}

@include medium {
  .thead {
    display: none;
  }

  .th,
  .td {
    vertical-align: top;
    padding: 10px 0;
  }

  .td:first-child,
  .th:first-child {
    padding-left: 10px;
  }

  .at {
    font-size: var(--small-font-size);
    font-weight: 500;
    line-height: 100%;
    color: var(--font-color-muted);
    display: block;
  }

  .commandName {
    display: none;
  }

  .BTNDetailsDesk {
    display: none !important;
  }

  .BTNDetailsMobile {
    display: flex !important;
    width: 24px;
    height: 24px;

    svg {
      margin: 12px 0 0 -2px;
    }
  }

  .tdMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    .commandName {
      font-size: var(--controls-font-size);
      line-height: 120%;
      display: block;
    }
  }

  .BTNDetails {
    display: flex !important;
  }

  .title,
  .subtitle {
    font-size: var(--controls-font-size);
    line-height: 120%;
  }

  .title {
    font-size: var(--small-font-size);
    display: flex;
    flex-direction: column;

    span {
      line-height: 120%;
    }
  }

  .subtitle {
    font-size: var(--small-font-size);
    color: var(--ui-accent-color-hover);
  }

  .day {
    font-size: var(--small-font-size);
    font-weight: 500;
  }

  .build {
    display: none;
  }

  // events

  // leagueList
  .block {
    width: 100%;
    margin-bottom: 30px;
  }

  .card {
    height: $heightCardMobile;
    margin-right: 0;
    padding: 0 10px;
    border-radius: 6px;
  }

  .name {
    font-size: var(--p-font-size);
  }

  .BTNFollowing {
    font-size: var(--controls-font-size);
  }
}

.dropDown {
  font-size: var(--controls-font-size);
  line-height: 17px;
  color: #828282;
  box-sizing: border-box;
  padding: 8px 20px 5px;
  border: 1px solid var(--ui-borders-color);
  border-radius: 20px;
}

.iconWrapper {
  position: absolute;
  top: 2px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}

.filterWindow {
  position: absolute;
  display: none;
  box-sizing: border-box;
  width: 300px;
  margin-top: 5px;
  box-shadow: var(--dropdown-shadow-color);
  border: 1px solid var(--ui-borders-color);
  border-radius: 12px;
  background-color: var(--dropdown-background-color);
}

.filterWrapper {
  position: relative;
  width: 100%;
  max-width: 230px;
  margin-left: 20px;

  :global {
    .toggleSelect {
      display: block;
    }

    .rotateArrow {
      transform: rotate(270deg);
    }
  }
}

.controlWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 15px;

  .games {
    display: flex;
    min-height: 35px;
    margin: 0 0 0 auto;
  }

  .gamesItem {
    label {
      padding: 2px 10px;
    }
  }

  .datePicker {
    font-size: var(--controls-font-size);
    width: 227px;
    margin-right: 25px;
  }

  .datePickerOutput {
    color: var(--font-color-muted);
    height: 35px;
    padding: 10px 20px;
    border-color: var(--ui-borders-color);

    &::after {
      display: none;
    }
  }

  .clearAll {
    font-size: var(--subtitle-font-size);
    color: inherit;
    white-space: nowrap;
    margin-left: 30px;
    cursor: pointer;
    border-bottom: 1px solid var(--font-color-primary);
  }
}

.modal {
  width: 100%;
}

.collapseTitle {
  display: flex;
  justify-content: space-between;
}

.collapseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all var(--transition-duration);
  transform: rotate(90deg);
  -webkit-tap-highlight-color: transparent;

  &.closedCollapseIcon {
    transform: rotate(-90deg);
  }
}

@include forNotebook {
  .controlWrapper {
    .datePicker {
      width: 185px;
      margin-right: 15px;
    }
  }
}

@include large {
  .BTNFollowing {
    &.BTNFollowingBigView {
      font-size: var(--controls-font-size);
      width: 100%;
    }
  }

  .clearAll {
    align-self: flex-end;
  }

  .controlWrapper {
    margin-top: 25px;
  }

  .datePicker {
    width: 100% !important;
    margin-bottom: 25px;
  }

  .datePickerOutput {
    height: 35px !important;
    padding: 0 20px !important;

    &::after {
      display: none !important;
    }
  }

  .mobileFilterItemLabel {
    font-size: var(--p-font-size);
    font-weight: 700;
    margin-bottom: 15px;
  }

  .mobileToolsWrapper {
    width: 100%;
  }

  .mobileTools {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .upcomingLabel {
      font-size: var(--p-font-size);
      font-weight: 700;
    }
  }

  .mobileInfoWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  .mobileInfoItem {
    font-size: var(--controls-font-size);
    display: inline-block;
    margin: 0 16px 5px 0;
    padding: 2px 10px;
    border: 1px solid var(--ui-borders-color);
    border-radius: 20px;
  }
}
