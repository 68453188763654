$width: 33px;
$height: 32px;
$minHeightWrapper: 39px;

.root {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 15px 0;
}

.pageSizeWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  select {
    padding: 5px;
    border-radius: 5px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-height: $minHeightWrapper;
}

.numberBTN {
  font-size: var(--controls-font-size);
  font-weight: 500;
  color: var(--pagination-menu-boder-color);
  width: $width;
  height: $height;
  margin-right: 4px;
  border-color: var(--pagination-menu-boder-color);
}

.active {
  color: var(--ui-accent-color);
  border-color: var(--ui-accent-color);
}

.arrowBTN:first-child {
  transform: rotate(180deg);
}

.numberBTN:nth-last-child(-n + 2) {
  margin-right: 0;
}
