@import '/styles/base';

.title {
  margin: 0;
}

.bottomMenu {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 250px;
  margin-top: auto;
  padding-top: 40px;
}

.BTN {
  text-decoration: underline;
}

.bottomMenu::before {
  position: absolute;
  right: 0;
  bottom: 60px;
  left: -10vw;
  width: 150vw;
  height: 1px;
  content: '';
  background-color: var(--ui-borders-color);
}

.topMenu {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;

  button:last-of-type {
    margin-left: auto;
  }
}

.topMenu::after {
  position: absolute;
  bottom: -10px;
  left: -20px;
  width: calc(100% + 40px);
  height: 1px;
  content: '';
  background-color: var(--ui-background-secondary);
}
