@import '/styles/base';

.wrapper {
  position: fixed;
  z-index: 9999;
  display: flex;
  overflow-x: clip;
  overflow-y: hidden;
  flex-direction: column;
  min-width: 200px;
  //max-height: 300px;
  padding: 20px 25px 12px;
  box-shadow: var(--dropdown-shadow-color);
  border: 1px solid var(--ui-background-secondary);
  border-radius: 12px;
  background-color: var(--modal-background-color);
}

.position {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.shadow::after {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: var(--modal-background-shadow-color);
}

@include large {
  .adaptive {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100% !important;
    max-height: 100%;
    margin: 0;
    transform: none;
    border-radius: 0;
  }
}
