@import '/styles/base';

$smallAvatarWidth: 36px;
$smallAvatarHeight: 36px;

$mediumAvatarWidth: 80px;
$mediumAvatarHeight: 80px;

$largeAvatarWidth: 100px;
$largeAvatarHeight: 100px;

$mediumAvatarWidthMobile: 60px;
$mediumAvatarHeightMobile: 60px;

.container {
  border: 1px solid var(--ui-borders-color);
  display: grid;
  position: relative;
  &__text {
    display: grid;
    justify-items: center;
    align-content: center;
  }
}

.clickableAvatar:hover {
  border: 1px solid var(--ui-accent-color-hover);
}

.small {
  height: $smallAvatarHeight;
  width: $smallAvatarWidth;
  border-radius: 40px;
}

.medium {
  height: $mediumAvatarHeight;
  width: $mediumAvatarWidth;
  border-radius: 60px;
}

.large {
  height: $largeAvatarHeight;
  width: $largeAvatarWidth;
  border-radius: 60px;
}

@include forPhone {
  .medium {
    height: $mediumAvatarWidthMobile;
    width: $mediumAvatarHeightMobile;
    border-radius: 50px;
  }
}
