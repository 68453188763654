@import 'styles/base';

$borderRadius: 12px;

.table_wrapper {
  min-height: 625px;
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-spacing: 0 5px;
  overflow-x: auto;
}

.table_head_item {
  color: var(--font-color-muted);
  font-weight: 100;
  text-align: start;
  padding: 11px 60px 0 0;
}

.table_head_title {
  display: flex;
  align-items: center;
  cursor: pointer;
  .table_head_arrow {
    margin-left: 5px;
    rotate: 90deg;
    width: 20px;
    height: 20px;
    path {
      fill: var(--font-color-muted);
    }
  }
  .asc {
    rotate: -90deg;
    fill: var(--font-color-primary);
  }
  .desc {
    fill: var(--font-color-primary);
  }
}

.table_head_item:first-child {
  padding-left: 25px !important;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--ui-background-secondary);
  opacity: 0.4;
  border: none;
  margin: 0;
}

.table_row .table_data {
  height: 60px;
  background-color: var(--ui-background-booking);
}

.table_data {
  cursor: pointer;
  padding: 0 5px 0 0;

  .capitalized {
    text-transform: capitalize;
  }
}

.table_data:first-child {
  padding-left: 25px !important;
}

.table_data:first-child {
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.table_data:last-child {
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.table_data_mobile {
  display: none;
}

.container_identificator {
  display: flex;
  align-items: center;

  &_avatar {
    margin: 0 5px 0 0;
  }

  &_name {
    font-weight: bold;
    display: flex;
  }

  &_lastName {
    margin-left: 5px;
  }
}

.total_price {
  font-weight: bold;
}

.order_status {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  width: 120px;
  height: 26px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 0 12px;

  &_pending {
    color: var(--star-color);
  }

  &_done {
    color: var(--ui-step-filled);
  }

  &_cancelled {
    color: var(--brand-color-error);
  }
}

.order_status:focus {
  border: none;
}

@include forPhone {
  .table_head {
    display: none;
  }

  .table_data {
    padding: 10px 0;
  }

  .table_data:first-child {
    padding-left: 10px;
  }

  .table_data_desktop {
    display: none;
  }

  .table_data_mobile {
    font-size: var(--small-font-size);
    display: block;
  }

  .separator {
    display: none;
  }
}

.modal {
  width: 100%;
}
