@import '/styles/base';

.section_title {
  margin: 30px 10% 0px 30px;
  font-size: var(--h3-font-size);
  font-weight: bold;
}

.general_info_wrapper {
  margin: 30px 10% 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--ui-background-booking);
  min-height: 250px;
  width: calc(90% - 30px);
  box-sizing: border-box;
  border-radius: 12px;
}

.affiliate_link_wrapper {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  &_title {
    font-size: var(--h4-font-size);
    font-weight: bold;
  }

  .valueWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.input {
  color: var(--font-color-primary);
  width: 100%;
  max-width: 430px;
  padding: 15px 10px 15px 50px;
  transition: border-color var(--transition-duration);
  border: 1px solid var(--ui-borders-color);
  border-radius: 40px;
  outline: none;

  &:focus {
    border-color: var(--ui-accent-color);
  }

  &::placeholder {
    color: var(--font-color-muted);
  }
}

.iconWrapper {
  position: absolute;
  top: 12px;
  left: 15px;

  svg {
    color: var(--font-color-secondary);
  }
}

.sales_search {
  display: flex;
  margin: 30px 10% 0 30px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4px;

  .search_wrapper {
    position: relative;
    margin-top: 25px;
    width: 100%;
  }

  .filter_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_export {
      align-items: flex-end;
    }

    label {
      font-size: 12px;
      padding-left: 12px;
    }

    select {
      width: 100%;
      max-width: 200px;
      color: var(--font-color-primary);
      padding: 15px 10px;
      transition: border-color var(--transition-duration);
      border: 1px solid var(--ui-borders-color);
      border-radius: 40px;
    }

    .datePicker {
      font-size: var(--controls-font-size);
      max-width: 200px;
      min-width: auto;
      width: 100%;
    }

    .datePickerOutput {
      color: var(--font-color-muted);
      height: 49px;
      padding: 10px 20px;
      border-color: var(--ui-borders-color);

      &::after {
        display: none;
      }
    }
  }
}

.table_wrapper {
  margin: 20px 10% 0 30px;
  font-size: 14px;
}

@include forDesktop {
  .export_button {
    height: 49px;
    width: 100%;
    max-width: 200px;
  }
}

@include forPhone {
  .container {
    display: block;
  }

  .affiliate_link_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .table_wrapper {
    margin: 10px 10px 0 10px;
  }

  .sales_search {
    flex-direction: column;

    .filter_wrapper {
      flex-direction: column;
      margin: 5px 0;

      select {
        max-width: 100%;
      }
    }
  }

  .sales_search .filter_wrapper .datePicker {
    max-width: inherit;
  }

  .export_button {
    height: 36px;
    width: 100%;
    margin-top: 20px;
  }
}
