@import 'styles/base';

.selectWrapper {
  width: 120px;
  position: relative;
}

.saleStatus {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: fit-content;
  outline: none;
  border: none;
  border-radius: 20px;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.saleStatusFont {
  font-family: var(--primary-font-family);
  font-weight: bold;
  font-size: var(--small-font-size);
  cursor: pointer;
  text-transform: capitalize;
}

.pending {
  color: var(--star-color);
}

.done {
  color: var(--ui-step-filled);
}

.cancelled {
  color: var(--brand-color-error);
}

.saleStatusFocus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, gray 50%);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
}

.saleStatus:focus {
  border: none;
}
